import React, { useRef, useCallback, useContext } from "react";
import ReactWebCam from "react-webcam";

import deleteImg from "../../images/delete.svg";
import cameraImg from "../../images/photography.svg";
import css from "./WebCam.module.css";

import { MainContext } from "../../config";

function WebCam() {
  const [context, setContext] = useContext(MainContext);
  const webcamRef = useRef(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setContext({ ...context, imageSrc });
  }, [webcamRef, context, setContext]);

  if (context.isDuckDuckGo) {
    return (
      <div style={{ padding: "1em" }}>
        <h1>Duck Duck No</h1>
        <p>
          Sorry, this cool feature doesn't work on DuckDuckGo{" "}
          <span role="img" aria-label="duck">
            🦆
          </span>
          <span role="img" aria-label="duck">
            🦆
          </span>
          <span role="img" aria-label="nogo">
            ❌
          </span>
        </p>
      </div>
    );
  }

  return (
    <div className={css.cameraContainer}>
      {context.imageSrc ? (
        <>
          <img src={context.imageSrc} alt="just captured" />
          <button
            onClick={() => setContext({ ...context, imageSrc: null })}
            className={css.captureButton}
            style={{ backgroundImage: `url(${deleteImg})` }}
            aria-label="delete"
          ></button>
        </>
      ) : (
        <>
          <ReactWebCam ref={webcamRef} height={"100%"} width={"100%"} />
          <button
            onClick={capture}
            className={css.captureButton}
            style={{ backgroundImage: `url(${cameraImg})` }}
            aria-label="take photo"
          />
        </>
      )}
    </div>
  );
}

export default WebCam;
