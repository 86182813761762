import React, { useState } from "react"
import { navigate } from "gatsby"
import cn from "classnames"
import loadable from "@loadable/component"
import Blob from "../Blob"
import PureBackground from "../PureBackground"
import css from "./TheUnknownZone.module.css"
import { isTouchDevice } from "../../libs/index"
import blobRumble from "../../audio/blob.wav"
import blobClick from "../../audio/blob-click.wav"

const AudioPlayer = loadable(() => import("../AudioPlayer"))

const isMob = isTouchDevice()

function TheUnknownZone() {
  const [isLeaving, setIsLeaving] = useState(false)

  function handleClick() {
    setIsLeaving(true)
    setTimeout(() => {
      navigate("/", { state: { isBackFromBlob: true } })
    }, 1000)
  }

  return (
    <>
      <AudioPlayer
        silent
        controlled
        isPlaying={!isLeaving}
        audio={blobRumble}
      />
      <AudioPlayer
        silent
        controlled
        playOnce
        isPlaying={isLeaving}
        audio={blobClick}
      />
      <PureBackground
        image={"Stars.png"}
        imgStyle={{ filter: `brightness(70%)` }}
      >
        <div className={css.mainContainer}>
          <button
            className={cn(css.blobContainer, { [css.blobLeaving]: isLeaving })}
            onClick={handleClick}
            aria-label="back to main site"
          >
            <Blob isMob={isMob} />
          </button>
        </div>
        {isMob && <p className={css.instructions}>drag or click</p>}
      </PureBackground>
    </>
  )
}

export default TheUnknownZone
