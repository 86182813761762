import React, { useState, useEffect } from "react";
import cn from "classnames";
import css from "./Menu.module.css";
import DropDown from "../DropDown";

import { defaultMenus } from "../../../constants/content-menu";

function Menu({
  isExitingDropDown,
  setIsExitingDropDown,
  chooseFromMenu,
  extraOptions,
  hiddenMenus = [],
}) {
  const [activeIndex, setActiveIndex] = useState(null);
  useEffect(() => {
    if (isExitingDropDown) {
      setActiveIndex(null);
      setIsExitingDropDown(false);
    }
  }, [isExitingDropDown, setIsExitingDropDown]);

  function handleClick(e) {
    const { index } = e.target.dataset;
    const i = Number(index);
    if (i === activeIndex) {
      setActiveIndex(null);
    } else if (index > -1) {
      setActiveIndex(i);
    }
  }

  return (
    <div className={css.menuBar}>
      {[
        ...defaultMenus.filter(({ title }) => !hiddenMenus.includes(title)),
        ...(extraOptions ? extraOptions : []),
      ].map(({ title, options }, i) => {
        return (
          <div className={cn(css.buttonContainer, "unselectable")} key={title}>
            <button
              className={cn(css.button, { [css.active]: i === activeIndex })}
              onClick={handleClick}
              data-index={i}
            >
              <span>{title[0]}</span>
              {title.slice(1)}
            </button>
            <DropDown
              visible={i === activeIndex}
              options={options}
              chooseFromMenu={chooseFromMenu}
              setActiveIndex={setActiveIndex}
              isExitingDropDown={isExitingDropDown}
            />
          </div>
        );
      })}
    </div>
  );
}

export default Menu;
