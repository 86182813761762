import React from "react"


function Circle({ radius, color, height, width }) {
    return <svg height={height} width={width}>
        <circle
            cx={width / 2}
            cy={height / 2}
            r={radius}
            fill={color}
        ></circle>
    </svg>
}

export default Circle