import React, { memo } from "react"
import css from "./Footer.module.css"
const defaultMessage = "BUY DORCHA ALBUM NOW"
function Footer({ message = defaultMessage, width }) {
  return (
    <footer className={css.windowFooter} style={{ "--width": `${width}px` }}>
      <div className={"borderHighlightsReverse"}>
        <span>{message}</span>
      </div>
    </footer>
  )
}

export default memo(Footer)
