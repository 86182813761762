import { useState, useEffect } from "react"

async function getUserAgent(callback) {
  const res = await fetch("https://api.duckduckgo.com/?q=useragent&format=json")
  const data = await res.json()
  callback(data)
}

export function useIsDuckDuckGo() {
  const [isDuckDuckGo, setIsDuckDuckGo] = useState(false)

  useEffect(() => {
    getUserAgent(data => {
      if (data && data.Answer.includes("DuckDuckGo")) {
        setIsDuckDuckGo(true)
      }
    })
  }, [])

  return isDuckDuckGo
}
