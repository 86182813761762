import { content } from "./content"
import { MENU_ACTIONS } from "../config"
export const defaultMenus = [
  {
    title: "File",
    options: [
      {
        title: "Save Image",
        component: "save",
        action: { type: MENU_ACTIONS.DOWNLOAD_AS_IMAGE },
        isDisabled: context => {
          return !context.imageSrc || context.isDuckDuckGo
        },
      },
      { title: "Share Site", action: { type: MENU_ACTIONS.SHARE_FB } },
      {
        title: "Close",
        action: { type: MENU_ACTIONS.NAVIGATE, payload: { component: "/" } },
      },
      {
        title: "Exit",
        action: { type: MENU_ACTIONS.PERFORM_CRAZY_ANIMATION },
      },
    ],
  },
  {
    title: "Edit",
    options: [
      {
        title: "Photo-Shoot",
        action: {
          type: MENU_ACTIONS.NAVIGATE,
          payload: { component: "WEBCAM" },
        },
      },
      {
        title: "Paint",
        action: {
          type: MENU_ACTIONS.NAVIGATE,
          payload: { component: "PAINT" },
        },
      },
    ],
  },
  {
    title: "Navigate",
    options: content
      .filter(
        ({ includedInNavigationDropdown }) => includedInNavigationDropdown
      )
      .map(({ title }) => ({
        title: `${title[0].toUpperCase()}${title.slice(1)}`,
        action: {
          type: MENU_ACTIONS.NAVIGATE,
          payload: { component: `/${title}` },
        },
      })),
  },
]
