import React from "react";
import css from "./YouTubeViewer.module.css";
import closeImg from "../../images/close.svg";

function YouTubeViewer({ href, setCurrentVideoUrl }) {
  function handleClose() {
    setCurrentVideoUrl("");
  }
  return (
    <div className={css.container}>
      <iframe
        title="youtube"
        className={css.frame}
        src={href}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <button
        aria-label="close video"
        onClick={handleClose}
        className={css.closeButton}
        style={{ backgroundImage: `url(${closeImg})` }}
      ></button>
    </div>
  );
}

export default YouTubeViewer;
