import React, { useEffect, useState } from "react";
import cn from "classnames";
import css from "./Container.module.css";

const Container = React.forwardRef(
  (
    {
      children,
      transformCoords,
      handleMouseMove,
      handleMouseUp,
      isFullScreen,
      resize,
    },
    ref
  ) => {
    const [hasBeenFullScreen, setHasBeenFullScreen] = useState(false);
    const { x, y, width, height, cursor } = transformCoords;

    useEffect(() => {
      if (isFullScreen) {
        setHasBeenFullScreen(true);
      }
    }, [isFullScreen]);

    return (
      <div
        className={css.background}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onKeyDown={e => e.key === "Escape" && handleMouseUp(e)}
        role="button"
        tabIndex="0"
      >
        <div
          className={cn(
            css.mainContainer,
            { [css.fullScreen]: isFullScreen },
            { [css.animateIn]: !isFullScreen && !hasBeenFullScreen },
            { [css.animateBack]: hasBeenFullScreen && !isFullScreen }
          )}
          onMouseDown={resize}
          role="button"
          tabIndex="0"
        >
          <div
            ref={ref}
            style={{
              left: `${x}px`,
              top: `${y}px`,
              height: `${height}px`,
              width: `${isFullScreen ? width - 4 : width}px`,
              cursor: `${cursor}`,
            }}
            className={cn(css.container, "borderHighlights")}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
);

export default Container;
