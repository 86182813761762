import React from "react"
import Paint from "../components/Paint"
import WebCam from "../components/WebCam"

const PAINT = {
  content: <Paint src={null} />,
  size: { type: "fixed", height: 480 },
  title: "PAINT",
  message: `"Every canvas is a journey all it's own." - Helen Frankenthaler`,
}

const WEBCAM = {
  content: <WebCam />,
  size: { type: "fixed", height: 480 },
  title: "INSTADORCH",
  message: "Camera may not work in all browsers. Sorry if that's you!",
}
export const secondaryContent = { PAINT, WEBCAM }
