import React from "react"
import cn from "classnames"

import css from "./Background.module.css"
import Image from "../image"

function PureBackground({ children, image, color, imgStyle }) {
  return (
    <div
      className={cn(css.colorDiv)}
      style={color ? { backgroundColor: color } : {}}
    >
      {image && (
        <Image
          className={css.image}
          src={image}
          style={{ position: "fixed" }}
          imgStyle={imgStyle || { objectPosition: "top center" }}
        />
      )}
      {children}
    </div>
  )
}

export default PureBackground
