import React from "react"
import Image from "../image"
import css from "./Eyeballs.module.css"

function Eyeballs({ rotation }) {
  return (
    <div className={css.mainContainer}>
      <Image src="contact-eyes.png" className={css.eyes} />
      <div className={css.pupilsContainer}>
        <Image
          src="pupil.png"
          style={{ transform: `rotate(${rotation}deg)` }}
          className={css.pupils}
        />
        <Image
          src="pupil.png"
          style={{ transform: `rotate(${-rotation}deg)` }}
          className={css.pupils}
        />
      </div>
    </div>
  )
}

export default Eyeballs
