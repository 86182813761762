import React, { useEffect, useState } from "react"

import css from "./GigsPage.module.css"

const SONGKICK_KEY = process.env.GATSBY_SONGKICK_KEY

function useGigFetch(type) {
  const [gigs, setGigs] = useState(null)

  useEffect(() => {
    async function getData() {
      try {
        const res = await fetch(
          `https://api.songkick.com/api/3.0/artists/8622969/${type}.json?apikey=${SONGKICK_KEY}`
        )
        const data = await res.json()
        setGigs(data.resultsPage.results.event || null)
      } catch (err) {
        console.log(err)
      }
    }
    getData()
  }, [type])

  return gigs
}

function GigsPage() {
  const pastGigs = useGigFetch("gigography")
  const futureGigs = useGigFetch("calendar")

  return (
    <section className={css.section}>
      {futureGigs ? (
        <>
          <h2>Upcoming Gigs</h2>
          <ul>
            {futureGigs.map(({ displayName, uri }) => {
              return (
                <li key={displayName}>
                  <p>{displayName}</p>
                  <a href={uri}>go to event</a>
                </li>
              )
            })}
          </ul>
        </>
      ) : (
        <p>None just now, check back soon...</p>
      )}
      <h2>Past Gigs...</h2>
      {pastGigs && (
        <ul>
          {pastGigs.reverse().map(({ displayName }) => {
            return (
              <li key={displayName}>
                <p>{displayName}</p>
              </li>
            )
          })}
        </ul>
      )}
    </section>
  )
}

export default GigsPage
