export const DOWNLOAD_TYPES = {
    TECH_SPEC: "tech-spec",
    IMAGES: "images",
    BIO: "bio",
    ALL: "all"
}

export function downloadFile(url, name) {
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.target = "_blank";
    a.rel = "noreferrer";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export function downloadReducer(payload) {
    switch (payload.action) {
        case DOWNLOAD_TYPES.IMAGES:
            return downloadFile("/press-pack/dorcha-images.zip", "dorcha-images.zip");
        case DOWNLOAD_TYPES.TECH_SPEC:
            return downloadFile("/press-pack/dorcha-tech-spec.pdf", "dorcha-tech-spec.pdf");
        case DOWNLOAD_TYPES.BIO:
            return downloadFile("/press-pack/dorcha-bio.pdf", "dorcha-bio.pdf");
        case DOWNLOAD_TYPES.ALL:
            return downloadFile("/press-pack/dorcha-press-pack-all.zip", "dorcha-press-pack-all.zip");
        default:
            return
    }

}