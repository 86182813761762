import React, { useState, useEffect, useContext } from "react"
import cn from "classnames"
import css from "./DropDown.module.css"
import { MainContext } from "../../../config"

function DropDown({
  visible,
  options = [],
  chooseFromMenu,
  setActiveIndex,
  position,
  isExitingDropDown,
}) {
  const [secondaryMenuIndex, setSecondaryMenuIndex] = useState(null)
  const [context] = useContext(MainContext)

  function handleChoice({ action, i, secondaryDropdown }) {
    if (!secondaryDropdown) {
      setSecondaryMenuIndex(null)
      setActiveIndex(null)
      chooseFromMenu(action)
    } else {
      setSecondaryMenuIndex(i)
    }
  }

  function handleHover({ i, secondaryDropdown }) {
    setSecondaryMenuIndex(
      i === secondaryMenuIndex && !secondaryDropdown ? null : i
    )
  }

  useEffect(() => {
    if (isExitingDropDown) {
      setSecondaryMenuIndex(null)
    }
  }, [isExitingDropDown])

  return (
    <>
      {visible && options.length > 0 && (
        <div
          className={cn(css.dropDown, "borderHighlights", {
            [css.positionRight]: position === "right",
          })}
        >
          {options.map(({ title, action, options = [], isDisabled }, i) => {
            return (
              <div className={css.menuItemContainer} key={title}>
                <button
                  key={title}
                  disabled={isDisabled && isDisabled(context)}
                  onClick={() =>
                    handleChoice({
                      action,
                      i,
                      secondaryDropdown: Boolean(options.length),
                    })
                  }
                  onMouseOver={() =>
                    handleHover({
                      i,
                      secondaryDropdown: Boolean(options.length),
                    })
                  }
                  onFocus={() =>
                    handleHover({
                      i,
                      secondaryDropdown: Boolean(options.length),
                    })
                  }
                  styling="dropdown"
                  className={css.dropDownMenuItem}
                >
                  <span>{title}</span>
                  <span>{Boolean(options.length) && ">"}</span>
                </button>
                <DropDown
                  visible={i === secondaryMenuIndex}
                  options={options}
                  position="right"
                  setActiveIndex={setActiveIndex}
                  chooseFromMenu={chooseFromMenu}
                  isExitingDropDown={isExitingDropDown}
                />
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

export default DropDown
