import React from "react"
import cn from "classnames"
import closeImg from "../../../images/close.svg"
import squareImg from "../../../images/square.svg"
import questionImg from "../../../images/question-mark.svg"
import minimizeImg from "../../../images/minus.svg"
import css from "./ActionButtons.module.css"
import useWindowSize from "../../../hooks/useWindowSize"

function ActionButtons({
  hideWindow,
  handleFullScreen,
  showHelpScreen,
  isFullScreen,
  isFullScreenDisabled,
}) {
  const { width } = useWindowSize()

  function stopPropagation(e) {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
  }
  return (
    <div
      className={css.buttonsContainer}
      onMouseUp={stopPropagation}
      onMouseDown={stopPropagation}
      role="presentation"
    >
      <button
        onClick={showHelpScreen}
        className={cn(css.button, "borderHighlights")}
        style={{ backgroundImage: `url(${questionImg})` }}
        aria-label="show help"
      ></button>
      {!isFullScreenDisabled && width > 600 && (
        <button
          onClick={handleFullScreen}
          className={cn(css.button, "borderHighlights")}
          style={{
            backgroundImage: `url(${isFullScreen ? minimizeImg : squareImg})`,
          }}
          aria-label={isFullScreen ? "minimize" : "fullscreen"}
        ></button>
      )}
      <button
        onClick={hideWindow}
        className={cn(css.button, "borderHighlights")}
        style={{ backgroundImage: `url(${closeImg})` }}
        aria-label="close window"
      ></button>
    </div>
  )
}

export default ActionButtons
