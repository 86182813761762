import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import SEO from "../seo"
import Window from "../Windows/Window"
import css from "./HomePage.module.css"

import { content } from "../../constants/content"
import { BEN_BOT_URL, BETH_URL } from "../../constants"

import DorchaLink from "../DorchaLink"
import GlitchText from "../GlitchText"
import RandomButton from "../RandomButton"
import SocialIcons from "../SocialIcons"

const title = "DORCHA"

function HomePage({ location }) {
  const [currentWindow, setCurrentWindow] = useState("")
  const [isGlitching, setIsGlitching] = useState(false)

  function closeWindow() {
    navigate("/")
  }

  useEffect(() => {
    if (location.pathname !== "/") {
      const pathname = location.pathname.replace(/\//g, "").toUpperCase()
      setCurrentWindow(pathname.toLowerCase())
    }
  }, [location, currentWindow])

  return (
    <>
      <SEO
        title={
          content.find(({ title }) => title === currentWindow)?.title ?? "Home"
        }
      />
      <div className={css.pageContainer}>
        <section className={css.mainContainer}>
          <div className={css.textContainer}>
            <h1 className={css.dorchaHeading}>
              <span aria-hidden="true" className={css.hackSpan}>
                |
              </span>
              <GlitchText text={title} isGlitching={isGlitching}></GlitchText>
            </h1>
            <ul className={css.mainList}>
              {content.map(
                ({
                  title,
                  isSpecialHover,
                  isDisabled,
                  usesTransition,
                  hoverText,
                  isExternal,
                  href,
                }) => (
                  <li key={title}>
                    <DorchaLink
                      text={title}
                      to={`/${title}`}
                      state={{
                        isBackFromBlob: usesTransition,
                      }}
                      isDisabled={isDisabled}
                      isSpecialHover={isSpecialHover}
                      isGlitching={isGlitching}
                      hoverText={hoverText}
                      isExternal={isExternal}
                      href={href}
                    />
                  </li>
                )
              )}
            </ul>
          </div>
        </section>
        <footer className={css.footer}>
          <SocialIcons />
          <p>
            Website built by{" "}
            <a className={css.footerLink} href={BEN_BOT_URL}>
              mrbenbot
            </a>
            {` & `}
            <a className={css.footerLink} href={BETH_URL}>
              Beth Bellis
            </a>{" "}
          </p>
          <p>Copyright © DORCHA {new Date().getFullYear()}</p>
        </footer>
      </div>
      <RandomButton
        isGlitching={isGlitching}
        setIsGlitching={setIsGlitching}
        location={location}
      />
      {location.pathname !== "/" && (
        <Window
          closeWindow={closeWindow}
          content={content.find(({ title }) => title === currentWindow)}
        />
      )}
    </>
  )
}

export default HomePage
