import React, { useState, useContext } from "react";
import { navigate } from "gatsby";
import cn from "classnames";

import ActionButtons from "../ActionButtons";
import Menu from "../Menu";
import Footer from "../Footer";
import Container from "../Container";
import EggTimer from "../../EggTimer";
import TheUnknownZone from "../../TheUnknownZone";
import HelpWindowInner from "../../HelpWindowInner";
import SaveImageWindow from "../../SaveImageWindow";
import Image from "../../image";
import Portal from "../../Portal";

import { secondaryContent } from "../../../constants/content-secondary";

import useDragBox from "../../../hooks/useDragBox";
import useImageDownload from "../../../hooks/useImageDownload";
import { downloadReducer } from "../../../hooks/downloadFile";

import css from "./Window.module.css";

import { MainContext, MENU_ACTIONS } from "../../../config";
import { useIsDuckDuckGo } from "../../../hooks/isDuckDuckGo";
import { useEffect } from "react";

const fallBackContent = {
  content: <p>Whoopsy, something went wrong. please try again...</p>,
  message: "Ooops, something went wrong...",
};

function Window({ closeWindow, content = fallBackContent, index }) {
  const { nodeRef, download, isImageLoading } = useImageDownload();
  const [isExitingDropDown, setIsExitingDropDown] = useState(false);
  const [isSaveAsPopupShown, setIsSaveAsPopupShown] = useState(false);
  const [isHelpScreenShown, setIsHelpScreenShown] = useState(false);
  const [context, setContext] = useContext(MainContext);
  const { handleMouseDown, handleFullScreen, ...dragBox } = useDragBox({
    ...content,
    ...context.currentMiniGame,
  });
  const isDuckDuckGo = useIsDuckDuckGo();

  useEffect(() => {
    if (isDuckDuckGo && !context.isDuckDuckGo) {
      setContext({ ...context, isDuckDuckGo });
    }
  }, [isDuckDuckGo, context, setContext]);

  async function chooseFromMenu(action = {}) {
    switch (action.type) {
      case MENU_ACTIONS.NAVIGATE:
        const { component } = action.payload;
        if (component === "PAINT" && !context.imageSrc) {
          console.log(content.imageSrc);
          return setContext({
            ...context,
            imageSrc: content.imageSrc,
            currentMiniGame: secondaryContent[component],
          });
        }
        if (component[0] === "/") {
          setContext({ ...context, currentMiniGame: null });
          navigate(component);
        }
        return setContext({
          ...context,
          currentMiniGame: secondaryContent[component],
        });
      case MENU_ACTIONS.DOWNLOAD_AS_IMAGE:
        return setIsSaveAsPopupShown(true);
      case MENU_ACTIONS.DOWNLOAD_CONTACT:
        return downloadReducer(action.payload);
      case MENU_ACTIONS.SHARE_FB:
        if (typeof window !== "undefined") {
          return window.open(
            "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdorcha.netlify.com%2F&amp;src=sdkpreparse",
            "_blank"
          );
        }
        break;
      case MENU_ACTIONS.PERFORM_CRAZY_ANIMATION:
        return setContext({ ...context, isCrazyAnimationHappening: true });
      case MENU_ACTIONS.SHOW_HELP_WINDOW:
        return setIsHelpScreenShown(true);
      default:
        return;
    }
  }

  function hideWindow() {
    setContext({ ...context, currentMiniGame: null, imageSrc: null });
    closeWindow(index);
  }
  function exitDropDown() {
    setIsExitingDropDown(true);
  }
  function showHelpScreen() {
    setIsHelpScreenShown(true);
  }

  return (
    <>
      <Container {...dragBox} ref={nodeRef}>
        <div
          className={css.topBar}
          onMouseDown={!dragBox.isFullScreen ? handleMouseDown : undefined}
          onDoubleClick={!dragBox.isFullScreen ? handleFullScreen : undefined}
          style={{ cursor: !dragBox.isFullScreen && "move" }}
          role="button"
          tabIndex="0"
          onKeyDown={e => e.key === "f" && handleFullScreen(e)}
        >
          <IconTextContainer
            text={`${context.currentMiniGame?.title ??
              content?.title ??
              "Bloop"} -
              Dorcha`}
            icon={content.icon}
          />
          <ActionButtons
            handleFullScreen={handleFullScreen}
            hideWindow={hideWindow}
            showHelpScreen={showHelpScreen}
            isFullScreenDisabled={context && content.isFullScreenDisabled}
            {...dragBox}
          />
        </div>
        <Menu
          isExitingDropDown={isExitingDropDown}
          setIsExitingDropDown={setIsExitingDropDown}
          chooseFromMenu={chooseFromMenu}
          extraOptions={context.currentMiniGame?.menu ?? content?.menu ?? null}
          hiddenMenus={
            context.currentMiniGame?.hiddenMenus ??
            content?.hiddenMenus ??
            undefined
          }
        />
        <div
          className={cn(css.content, "borderHighlightsReverse")}
          onClick={exitDropDown}
          onKeyDown={e => e.key === "enter" && exitDropDown(e)}
          role="button"
          tabIndex="0"
        >
          {context.currentMiniGame
            ? context.currentMiniGame.content
            : content.content}
        </div>
        <Footer
          message={context.currentMiniGame?.message ?? content.message ?? ""}
          width={dragBox.transformCoords.width}
        />
      </Container>
      {isHelpScreenShown && (
        <WindowPicker
          type="help"
          closeWindow={() => setIsHelpScreenShown(false)}
        ></WindowPicker>
      )}
      {isSaveAsPopupShown && (
        <WindowPicker
          type="save-image"
          closeWindow={() => setIsSaveAsPopupShown(false)}
          download={download}
        ></WindowPicker>
      )}
      {context.isCrazyAnimationHappening && (
        <Portal>
          <TheUnknownZone />
        </Portal>
      )}
      {isImageLoading && (
        <Portal>
          <EggTimer />
        </Portal>
      )}
    </>
  );
}

function HelpWindow({ title, children, size }) {
  const { handleMouseDown, handleFullScreen, ...dragBox } = useDragBox(size);
  return (
    <Container {...dragBox}>
      <div
        className={css.topBar}
        onMouseDown={!dragBox.isFullScreen ? handleMouseDown : undefined}
        onDoubleClick={!dragBox.isFullScreen ? handleFullScreen : undefined}
        style={{ cursor: !dragBox.isFullScreen && "move" }}
        role="button"
        tabIndex="0"
        onKeyDown={e => e.key === "f" && handleFullScreen(e)}
      >
        <IconTextContainer text={title} icon="leg.png" />
      </div>
      {children}
    </Container>
  );
}

function IconTextContainer({ icon, text }) {
  return (
    <div className={css.iconTextContainer}>
      <Image className={css.wordIcon} src={icon} alt="icon"></Image>
      <p className={cn(css.windowTitle, "unselectable")}>{text}</p>
    </div>
  );
}

function WindowPicker({ type, ...props }) {
  switch (type) {
    case "help":
      return (
        <HelpWindow
          {...props}
          title="HELP"
          size={{
            size: { type: "custom", width: 415, height: 415, x: 250, y: 150 },
            minWidth: 415,
            minHeight: 415,
          }}
        >
          <HelpWindowInner {...props} />
        </HelpWindow>
      );
    case "save-image":
      return (
        <HelpWindow
          {...props}
          title="SAVE IMAGE"
          size={{
            size: { type: "fixed", width: 415, height: 225, x: 250, y: 150 },
          }}
        >
          <SaveImageWindow {...props} />
        </HelpWindow>
      );
    default:
      return <Window {...props} />;
  }
}

export default WindowPicker;
