import React from "react"

import Layout from "../components/layout"
import HomePage from "../components/HomePage"

const IndexPage = ({ location }) => {
  return (
    <Layout image="dorcha-hair.png" location={location}>
      <HomePage location={location} />
    </Layout>
  )
}

export default IndexPage
