import React from "react";
import css from "./EggTimer.module.css";

import gif from "./EggTimer.gif";

function EggTimer() {
  return (
    <div className={css.mainContainer}>
      <div className={css.eggContainer}>
        <img src={gif} className={css.eggTimer} alt="egg timer" />
      </div>
    </div>
  );
}

export default EggTimer;
