import React from "react";
import MusicPage from "../components/MusicPage";
import GigsPage from "../components/GigsPage";
import ContactPage, { contactMenu } from "../components/ContactPage";
import songKickSvg from "../images/songkick.svg";

import albumImage from "../images/album-background-2.png";

const ALBUM = {
  title: "album",
  message: "Album coming v v soon",
  isDisabled: false,
  isSpecialHover: true,
  usesTransition: true,
  hoverText: "HONEY BADGER",
  includedInNavigationDropdown: true,
};

const GIGS = {
  title: "gigs",
  content: <GigsPage />,
  message: (
    <img
      style={{ height: `26px` }}
      src={songKickSvg}
      alt="song kick logo"
    ></img>
  ),
  size: { type: "normal" },
  icon: "Dog.png",
  includedInNavigationDropdown: true,
  imageSrc: albumImage,
};

const MUSIC = {
  title: "music",
  content: <MusicPage />,
  message: (
    <p style={{ fontSize: `7px`, margin: 0, padding: "5px" }}>
      `"Music makes one feel so romantic – at least it always gets on one’s
      nerves – which is the same thing nowadays."― Oscar Wilde`
    </p>
  ),
  size: { type: "normal" },
  icon: "Griphand.png",
  includedInNavigationDropdown: true,
  imageSrc: albumImage,
};

const MERCH = {
  title: "merch",
  isExternal: true,
  href: "https://dorcha.bandcamp.com/merch",
};
const ARCADE = {
  title: "arcade",
  isSpecialHover: true,
  usesTransition: true,
  isExternal: true,
  href: "https://honey-badger.netlify.app/",
  hoverText: "ARCADE",
};
const CONTACT = {
  title: "contact",
  content: <ContactPage />,
  size: {
    type: "fixed",
    x: 50,
    y: 50,
    width: 450,
    height: 550,
  },
  isFullScreenDisabled: true,
  icon: "Lizard.png",
  menu: contactMenu,
  includedInNavigationDropdown: true,
  imageSrc: albumImage,
  hiddenMenus: ["Navigate"],
  message: "dorchamusic@gmail.com",
};

export const content = [ALBUM, MUSIC, GIGS, MERCH, CONTACT, ARCADE];
