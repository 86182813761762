import React, { useState, useEffect } from "react";
import loadable from "@loadable/component";
import useWindowSize from "../../hooks/useWindowSize";
import { isTouchDevice } from "../../libs/index";

import glitchSound from "../../audio/glitch-hover.wav";
import css from "./RandomButton.module.css";
const AudioPlayer = loadable(() => import("../AudioPlayer"));

const isMob = isTouchDevice();

function RandomButton({ isGlitching, setIsGlitching, location }) {
  const { width, height } = useWindowSize();
  const [coords, setCoords] = useState({});

  useEffect(() => {
    const top = Math.random() * (height - 200);
    const left = Math.random() * (width - 200);
    setCoords({ top, left });
  }, [width, height]);

  useEffect(() => {
    if (isMob) {
      if (!isGlitching) {
        let id = setTimeout(() => {
          setIsGlitching(true);
        }, 10 * 1000 * Math.random() + 1000);
        return () => {
          clearTimeout(id);
        };
      } else {
        let id = setTimeout(() => {
          setIsGlitching(false);
        }, 1000 * Math.random() * 4 + 1000);
        return () => {
          clearTimeout(id);
        };
      }
    }
  }, [isGlitching, setIsGlitching]);

  if (location.pathname !== "/") {
    return <></>;
  }
  if (isMob) {
    return (
      <AudioPlayer
        silent
        controlled
        autoPlay={false}
        isPlaying={isGlitching}
        audio={glitchSound}
        fallback={<></>}
      />
    );
  }

  const { top, left } = coords;

  return (
    <>
      <button
        className={css.button}
        style={{ top: `${top}px`, left: `${left}px` }}
        onMouseEnter={() => setIsGlitching(true)}
        onMouseOut={() => setIsGlitching(false)}
        onBlur={() => setIsGlitching(false)}
        onClick={() => typeof window !== "undefined" && alert("no")}
        aria-label="Yes?"
      ></button>
      <AudioPlayer
        silent
        controlled
        isPlaying={isGlitching}
        audio={glitchSound}
        fallback={<></>}
      />
    </>
  );
}

export default RandomButton;
