import React from "react";
import cn from "classnames"
import Image from "../image"
import css from "./HelpWindowInner.module.css"

import useWindowSize from "../../hooks/useWindowSize"

function HelpWindowInner({ closeWindow }) {
    const { width } = useWindowSize();
    return <section className={css.helpContainer}>
        <div className={cn(css.imgContainer, "borderHighlightsReverse")}>
            <Image src="dialup.png" className={css.img} />
        </div>
        <div className={css.text}>
            <p>Welcome, are you having problems?</p>
            <p>Try these things</p>
            <ul>
                {width > 600 &&
                    <>
                        <li>Move the windows about</li>
                        <li>Resize at your leasure</li>
                    </>
                }
                <li>Visit the arcade</li>
                <li>Get creative</li>
            </ul>
        </div>
        <div className={css.buttonContainer}>
            <button
                className={cn(css.button, "borderHighlights")}
                onClick={closeWindow}
            >
                CLOSE
          </button>
        </div>
    </section>
}

export default HelpWindowInner