import { useRef, useState } from "react"

function useImageDownload() {
  const nodeRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)

  async function download() {
    if (nodeRef) {
      setIsLoading(true)
      const canvases = nodeRef.current.querySelectorAll("canvas")
      const urls = Array.from(canvases)
        .map(canv => canv.toDataURL())
        .reverse()
      const canvas = document.createElement("canvas")
      var context = canvas.getContext("2d")
      const { width, height } = canvases[0]
      canvas.width = width
      canvas.height = height
      try {
        const images = await Promise.all(
          urls.map(dataUrl => {
            return new Promise((res, rej) => {
              var image = new Image()
              image.onload = function() {
                res(this)
              }
              image.onerror = rej
              image.src = dataUrl
            })
          })
        )
        images.forEach(image =>
          context.drawImage(image, 0, 0, canvas.width, canvas.height)
        )
      } catch (err) {
        console.log(err)
      }
      const a = document.createElement("a")
      a.style.display = "none"
      a.href = canvas.toDataURL()
      a.download = "dorcha.png"
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)

      setIsLoading(false)
      return
    }
    console.log("no nodeRef...")
  }

  return {
    nodeRef,
    download,
    isImageLoading: isLoading,
  }
}

export default useImageDownload
