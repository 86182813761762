import React from "react"

import Image from "../image"

import css from "./SocialIcons.module.css"

import { links } from "../../constants"

function SocialIcons() {
  return (
    <ul className={css.container}>
      {links.map(({ alt, src, href }) => (
        <li key={href}>
          <a href={href}>
            <Image src={src} alt={alt} className={css.img}></Image>
          </a>
        </li>
      ))}
    </ul>
  )
}

export default SocialIcons
