import { useState, useEffect, useCallback } from "react"

import useWindowSize from "./useWindowSize"

function getInitialSize(size, { width, height }) {
  switch (size.type) {
    case "bigger":
      return {
        x: width / 2 - 300,
        y: height / 2 - 250,
        height: 500,
        width: 600,
      }
    case "help":
      return {
        x: width / 2 - 257,
        y: height / 2 - 200,
        height: 400,
        width: 515,
      }
    case "custom":
    case "fixed":
      return {
        x: width / 2 - (size.width || 700) / 2,
        y: height / 2 - (size.height || 500) / 2,
        height: size.height || 500,
        width: size.width || 700,
      }
    default:
      return {
        x: width / 2 - 350,
        y: height / 2 - 250,
        height: 500,
        width: 700,
      }
  }
}

function useDragBox({
  size = {},
  maxHeight = 10000,
  maxWidth = 10000,
  minHeight = 200,
  minWidth = 300,
}) {
  const [startingMousePosition, setStartingMousePosition] = useState({
    clientX: 0,
    clientY: 0,
  })
  const windowSize = useWindowSize()
  const [boxPosition, setBoxPosition] = useState(() =>
    getInitialSize(size, windowSize)
  )
  const [transformCoords, setTransformCoords] = useState(() =>
    getInitialSize(size, windowSize)
  )
  const [isBoxMoving, setIsBoxMoving] = useState(false)
  const [isBoxResizing, setIsBoxResizing] = useState(false)
  const [isWidthResizing, setIsWidthResizing] = useState(false)
  const [isHeightResizing, setIsHeightResizing] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)

  const handleFullScreen = useCallback(
    function(e) {
      e && e.stopPropagation()
      e && e.nativeEvent.stopImmediatePropagation()
      if (!isFullScreen) {
        setIsFullScreen(true)
        return
      }
      setTransformCoords(boxPosition)
      setIsFullScreen(false)
    },
    [isFullScreen, boxPosition]
  )

  useEffect(() => {
    if (
      (size.type === "fullscreen" || windowSize.width < 600) &&
      !isFullScreen
    ) {
      handleFullScreen()
    }
  }, [windowSize, size, isFullScreen, handleFullScreen])

  useEffect(() => {
    if (isFullScreen) {
      const newSize = {
        x: 0,
        y: 0,
        ...windowSize,
      }
      setTransformCoords(newSize)
    }
  }, [isFullScreen, windowSize])

  function handleMouseDown(e) {
    // console.log("start")
    if (isFullScreen) return
    const { clientX, clientY } = e
    setStartingMousePosition({ clientX, clientY })
    setIsBoxMoving(true)
    handleCurser({ clientX, clientY })
  }

  function handleMouseMove({ clientX, clientY }) {
    if (isFullScreen) return
    handleCurser({ clientX, clientY })
    isBoxMoving && handleMovement({ clientX, clientY })
    isBoxResizing && handleResizing({ clientX, clientY })
  }
  function handleMovement({ clientX, clientY }) {
    if (isFullScreen) return
    const x = clientX - startingMousePosition.clientX + boxPosition.x
    const y = clientY - startingMousePosition.clientY + boxPosition.y
    setTransformCoords({ ...boxPosition, x, y })
  }
  function handleResizing({ clientX, clientY }) {
    if (isFullScreen) return
    let height = boxPosition.height
    let width = boxPosition.width

    if (isHeightResizing) {
      // console.log("resizing height")
      height = Math.max(
        minHeight,
        Math.min(
          boxPosition.height + (clientY - startingMousePosition.clientY),
          maxHeight
        )
      )
    }
    if (isWidthResizing) {
      // console.log("resizing width")
      width = Math.max(
        minWidth,
        Math.min(
          boxPosition.width + (clientX - startingMousePosition.clientX),
          maxWidth
        )
      )
    }
    setTransformCoords({ ...boxPosition, height, width })
  }

  function handleCurser({ clientX, clientY }) {
    if (isFullScreen) return
    if (size.type === "fixed") return
    switch (getMousePositionMeaning({ clientX, clientY, boxPosition })) {
      case "both":
        setTransformCoords({ ...transformCoords, cursor: "nwse-resize" })
        break
      case "x":
        setTransformCoords({ ...transformCoords, cursor: "ew-resize" })
        break
      case "y":
        setTransformCoords({ ...transformCoords, cursor: "ns-resize" })
        break
      case "none":
      default:
        setTransformCoords({ ...transformCoords, cursor: "initial" })
        break
    }
  }

  function handleMouseUp() {
    if (isFullScreen) return
    // console.log("end")
    setIsBoxMoving(false)
    setIsBoxResizing(false)
    setIsWidthResizing(false)
    setIsHeightResizing(false)
    setBoxPosition(transformCoords)
  }

  function resize({ clientX, clientY }) {
    if (isFullScreen) return
    if (size.type === "fixed") return
    setStartingMousePosition({ clientX, clientY })

    switch (getMousePositionMeaning({ clientX, clientY, boxPosition })) {
      case "both":
        setIsBoxResizing(true)
        setIsWidthResizing(true)
        setIsHeightResizing(true)
        break
      case "x":
        setIsBoxResizing(true)
        setIsWidthResizing(true)
        break
      case "y":
        setIsBoxResizing(true)
        setIsHeightResizing(true)
        break
      case "none":
      default:
        setIsBoxResizing(false)
        setIsWidthResizing(false)
        setIsHeightResizing(false)
        break
    }
  }

  return {
    transformCoords,
    handleMouseMove,
    handleMouseDown,
    handleMouseUp,
    handleFullScreen,
    resize,
    isFullScreen,
  }
}

function getDistance(x1, y1, x2, y2) {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
}
function getMousePositionMeaning({ clientX, clientY, boxPosition }) {
  const distanceY = getDistance(
    clientX,
    clientY,
    clientX,
    boxPosition.y + boxPosition.height
  )
  const distanceX = getDistance(
    clientX,
    clientY,
    boxPosition.x + boxPosition.width,
    clientY
  )
  if (distanceX < 10 && distanceY < 10) {
    return "both"
  } else if (distanceX < 10) {
    return "x"
  } else if (distanceY < 10) {
    return "y"
  } else {
    return "none"
  }
}
export default useDragBox
