import React from "react"
import cn from "classnames"

import Image from "../image"
import css from "./SaveImage.module.css"

function SaveImageWindow({ closeWindow, download }) {
  return (
    <section className={css.helpContainer}>
      <div className={css.textImageContainer}>
        <div className={css.text}>
          <p>Save your gorgeous image?</p>
          <p>Share it with us @dorchamusic</p>
        </div>
        <div className={css.imageContainer}>
          <Image src="honey-badger-swirly.png" className={css.image} />
        </div>
      </div>
      <div className={css.buttonContainer}>
        <button
          className={cn(css.button, "borderHighlights")}
          onClick={download}
        >
          Download
        </button>
        <button
          className={cn(css.button, "borderHighlights")}
          onClick={closeWindow}
        >
          CLOSE
        </button>
      </div>
    </section>
  )
}

export default SaveImageWindow
