import React, { useState, useEffect, useRef } from "react"
import css from "./ContactPage.module.css"


import Eyeballs from "../Eyeballs"

import { DORCHA_EMAIL } from "../../constants"
import { DOWNLOAD_TYPES } from "../../hooks/downloadFile"

const DOWNLOAD_ACTION = "download-contact"

const scrollingHeight = 100000


export const contactMenu = [{
  title: "Downloads",
  options: [
    {
      title: "Tech Spec",
      action: {
        type: DOWNLOAD_ACTION,
        payload: { action: DOWNLOAD_TYPES.TECH_SPEC },
      },
    },
    {
      title: "Bio",
      action: {
        type: DOWNLOAD_ACTION,
        payload: { action: DOWNLOAD_TYPES.BIO },
      },
    },
    {
      title: "Images",
      action: {
        type: DOWNLOAD_ACTION,
        payload: { action: DOWNLOAD_TYPES.IMAGES },
      },
    },
    {
      title: "Download All",
      action: {
        type: DOWNLOAD_ACTION,
        payload: { action: DOWNLOAD_TYPES.ALL },
      },
    },
  ]
}
]


function ContactPage() {
  const [rotation, setRotation] = useState(0)
  const ref = useRef(null)
  function handleScroll(e) {
    const { scrollTop } = e.target
    e.stopPropagation()
    setRotation(scrollTop)
  }
  useEffect(() => {
    if (ref) {
      ref.current.scrollTop = scrollingHeight / 2
    }
  }, [])

  return (
    <section ref={ref} className={css.container} onScroll={handleScroll}>
      <div className={css.content}>
        <div className={css.eyesContainer}>
          <Eyeballs rotation={rotation} />
        </div>
        <div className={css.emailContainer}>
          <a href={`mailto:${DORCHA_EMAIL}`}>{DORCHA_EMAIL}</a>
        </div>
      </div>
      <div className={css.scrollingContent} style={{ "--scrollingHeight": `${scrollingHeight}px` }}></div>
    </section>
  )
}
export default ContactPage
