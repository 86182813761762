import React, { useRef, useState, useContext, useEffect } from "react";
import CanvasDraw from "react-canvas-draw";
import cn from "classnames";

import css from "./Paint.module.css";
import { MainContext } from "../../config";
import * as actions from "./actionTypes";

import undoIcon from "../../images/arrows.svg";
import deleteIcon from "../../images/delete.svg";
import Circle from "./circle";

const lsTestKey = "test";
const DRAWING_STORAGE_KEY = "current_drawing";
function lsTest() {
  try {
    localStorage.setItem(lsTestKey, lsTestKey);
    const test = localStorage.getItem(lsTestKey);
    if (test) {
      localStorage.removeItem(lsTestKey);
      return true;
    }
  } catch {
    return false;
  }
}

// "#8fcf"
const colors = ["#ef343f", "#eeb4ff", "#fbd260", "#88ffcb"];
const brushSizes = [2, 5, 12, 25];
const maxBrush = Math.max(...brushSizes);
const actionsPanel = [
  { action: actions.UNDO, icon: undoIcon },
  { action: actions.CLEAR_DRAWING, icon: deleteIcon },
];

function Paint() {
  const canvasRef = useRef(null);
  const [color, setColor] = useState("rgb(100, 6, 255)");
  const [brushRadius, setBrushRadius] = useState(10);
  const [context, setContext] = useContext(MainContext);
  const { imageSrc } = context;

  const imageRef = useRef(imageSrc);

  function handleChoice({ action, payload } = {}) {
    switch (action) {
      case actions.UNDO:
        canvasRef.current.undo();
        break;
      case actions.CLEAR_DRAWING:
        canvasRef.current.clear();
        break;
      case actions.CHANGE_COLOR:
        setColor(payload.color);
        break;
      case actions.CHANGE_BRUSH_SIZE:
        setBrushRadius(payload.size);
        break;
      default:
        return;
    }
  }
  function handleExit() {
    const currentDrawing = canvasRef.current.getSaveData();
    if (lsTest()) {
      localStorage.setItem(DRAWING_STORAGE_KEY, currentDrawing);
    }
    setContext({ ...context, currentMiniGame: null });
  }
  useEffect(() => {
    if (lsTest() && canvasRef.current) {
      const currentDrawing = localStorage.getItem(DRAWING_STORAGE_KEY);
      currentDrawing && canvasRef.current.loadSaveData(currentDrawing);
    }
  }, [canvasRef]);
  return (
    <section className={css.container}>
      <CanvasDraw
        loadTimeOffset={5}
        ref={canvasRef}
        canvasWidth={"100%"}
        canvasHeight={"100%"}
        brushRadius={brushRadius}
        brushColor={color}
        hideInterface
        hideGrid
        imgSrc={imageRef.current}
        lazyRadius={0}
      />
      <footer className={css.footer}>
        {colors.map(color => (
          <button
            key={color}
            style={{ backgroundColor: color }}
            onClick={() => setColor(color)}
            className={css.colorButton}
            aria-label={color}
          ></button>
        ))}
        {brushSizes.map(radius => {
          return (
            <button
              aria-label={radius}
              key={radius}
              onClick={() => setBrushRadius(radius)}
              className={cn(css.brushButton, {
                [css.selected]: radius === brushRadius,
              })}
            >
              <Circle
                radius={radius}
                color={color}
                height={maxBrush * 2}
                width={maxBrush * 2}
              />
            </button>
          );
        })}
        {actionsPanel.map(({ icon, action }) => (
          <button
            key={action}
            className={css.iconButton}
            style={{ backgroundImage: `url(${icon})` }}
            onClick={() => handleChoice({ action })}
            aria-label={action}
          ></button>
        ))}
        <button className={css.backToPage} onClick={handleExit}>
          Back to page
        </button>
      </footer>
    </section>
  );
}

export default Paint;
