import React, { useState } from "react";
import cn from "classnames";
import YouTubeViewer from "../YouTubeViewer";
import Image from "../image";

import foreignLink from "../../images/foreign.svg";
import youtubeLink from "../../images/youtube.svg";

import { isTouchDevice } from "../../libs";
import css from "./MusicPage.module.css";
const isMob = isTouchDevice();

const musicInfo = {
  2015: [
    {
      title: "Black Streams Album",
      href: "https://dorcha.bandcamp.com/album/black-streams",
    },
    {
      title: "Ghostlust",
      bideo: "https://www.youtube.com/embed/6570J7j4vLA",
    },
    { title: "Duel", bideo: "https://www.youtube.com/embed/ILD6fE0m5L0" },
  ],
  2016: [
    { title: "ISM EP", href: "https://dorcha.bandcamp.com/album/ism-ep" },
    { title: "Crimson", bideo: "https://www.youtube.com/embed/FL7EOYp4A0w" },
    {
      title: "Two Steps",
      bideo: "https://www.youtube.com/embed/CGigKRku9G0",
    },
    {
      title: "Black Streams alt.",
      bideo: "https://www.youtube.com/embed/rlw2hCjHcXw",
    },
  ],
  2018: [
    {
      title: "Bruiser Single",
      href: "https://dorcha.bandcamp.com/track/bruiser",
      hoverImage: "disco-dog.png",
    },
    {
      title: "Bruiser",
      bideo: "https://www.youtube.com/embed/f3SsVOV2wS4",
      padding: true,
    },
    {
      title: "Miniatures Project",
      href: "https://dorcha.bandcamp.com/album/the-miniatures-project-vol-1",
      blurb:
        "Dorcha invited other composers to write for us to create an album of miniature tunes. Different heads, same body. Miniatures Vol I has minis from Stella Roberts, Anna Palmer, James Oldham, Natalie Mason and Ben Lee.",
    },
  ],
  2020: [
    {
      title: "Monkey Dust Single",
      href: "https://boxrecordsshop.bandcamp.com/album/honey-badger",
    },
    {
      title: "Monkey Dust Video",
      bideo: "https://www.youtube.com/embed/9MW7Y3IVTyQ",
      padding: true,
    },
  ],
};

function MusicPage() {
  const [currentVideoUrl, setCurrentVideoUrl] = useState("");
  function handleClick(e) {
    const url = e.target.getAttribute("data-href");
    if (url === currentVideoUrl) {
      return setCurrentVideoUrl("");
    }
    return setCurrentVideoUrl(url);
  }
  return (
    <section className={css.section}>
      <p>
        BUY EVERYTHING HERE --{">  "}
        <a
          href={`${process.env.GATSBY_BANDCAMP_URL ||
            "https://dorcha.bandcamp.com/"}`}
        >
          bandcamp
        </a>
      </p>
      <p>
        BUY HONEY BADGER ALBUM --{">  "}
        <a href={`https://boxrecordsshop.bandcamp.com/album/honey-badger`}>
          HERE
        </a>
      </p>
      {Object.entries(musicInfo)
        .sort(([year1], [year2]) => year2 - year1)
        .map(([year, recordings]) => {
          return (
            <div key={year}>
              <h2>{year}</h2>
              <ul className={css.list}>
                {recordings.map(
                  ({ title, href, bideo, blurb, padding, hoverImage }) => (
                    <li key={title} className={css.listItem}>
                      {href && (
                        <LinkWithOptionalHover
                          title={title}
                          href={href}
                          hoverImage={hoverImage}
                        />
                      )}
                      {bideo && (
                        <button
                          className={cn(css.button, {
                            [css.lastOfType]: padding,
                          })}
                          data-href={bideo}
                          onClick={handleClick}
                        >
                          {bideo && (
                            <img
                              className={css.youtubeLink}
                              src={youtubeLink}
                              data-href={bideo}
                              alt="youtube"
                            ></img>
                          )}
                          {title}
                        </button>
                      )}
                      {!href && !bideo && title}
                      {bideo && currentVideoUrl === bideo && (
                        <YouTubeViewer
                          href={currentVideoUrl}
                          setCurrentVideoUrl={setCurrentVideoUrl}
                        ></YouTubeViewer>
                      )}
                      {blurb && <p className={css.blurb}>{blurb}</p>}
                    </li>
                  )
                )}
              </ul>
            </div>
          );
        })}
    </section>
  );
}

function LinkWithOptionalHover({ href, title, hoverImage }) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div style={{ position: "relative" }}>
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img
          className={css.foreignLink}
          src={foreignLink}
          alt="link to external icon"
        ></img>
        {title}
      </a>
      {(isHovered || isMob) && hoverImage && (
        <div
          style={{
            position: "absolute",
            left: isMob ? "170px" : "400px",
            top: isMob ? "-50px" : "-100px",
          }}
        >
          <Image src={hoverImage} style={{ width: isMob ? "75px" : "150px" }} />
        </div>
      )}
    </div>
  );
}

export default MusicPage;
